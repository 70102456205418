import '../styles/globals.scss'
import { Red_Hat_Display } from "next/font/google"
import type { AppProps } from 'next/app'
import Header from '../src/layout/Header'
import styles from '../styles/Home.module.scss'
import { Toaster } from 'react-hot-toast'

const redHat = Red_Hat_Display({
  subsets: ["latin"],
  weight: ["300", "400", "500", "600"],
})

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <style jsx global>{`
        html, body, * {
          font-family: ${redHat.style.fontFamily};
        }
      `}</style>
      <Header />
      <Toaster />
      <main className={styles.main}>
        <Component {...pageProps} />
      </main>
      {/* <footer className={styles.footer}></footer> */}
    </>
  )
}
